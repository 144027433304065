.forget_password {
    align-self: flex-end;
    color: black;
    cursor: pointer;
}

.show_hide_icon {
    position: absolute;
    top: 25px;
    right: 5px;
}

.login_input {
    padding: 18px 20px;
    border-radius: 8px;
    line-height: 21px;
    min-width: 567px;
    border: 1px solid #3C4242;
    cursor: pointer;
    user-select: none;
}

.login_btn {
    margin-top: 20px;
}

@media(max-width:768px) {
    .login_input {
        min-width: 90%;
    }
}