.input_text {
    size: 16px;
    line-height: 19.2px;
    color: #807d7e;
}

.container p {
    color: #807d7e;
    font-size: 18px;
    line-height: 27px;
}