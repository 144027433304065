.empty_cart {
    align-items: center;
    border-radius: 12px;
    max-height: 460px;
    padding: 45px 20px;
}

.empty_cart_icon {
    font-size: 60px;
    background-color: #F6F6F6;
    padding: 40px;
    border-radius: 50%;
}

.empty_cart h1 {
    font-weight: 400;
    font-size: 34px;
    line-height: 41.89px;
    text-align: center;
}

.empty_cart p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19.95px;
    text-align: center;
    letter-spacing: 2%;
    color: #807D7E;
}