.sidebar_container {
  width: 230px;
  border-right: 1px solid #bebcbd;
  background-color: white;
  height: 100vh;
  padding: 25px 14px;
  box-sizing: border-box;
  position: fixed;
  top: 87px;
}

.sidebar_item {
  justify-content: flex-start;
  align-items: center;
  padding: 15px 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  border-left: 2px solid transparent;
  text-decoration: none;
  color: black;
}

.sidebar_icons {
  font-size: 25px;
}

.sidebar_item.active {
  background-color: #f6f6f6;
  color: #333;
  border-left: 2px solid #bebcbd;
}