.single_card_container {
    padding: 0 100px;
}

.single_card_details_section {
    padding: 50px;
    box-sizing: border-box;
    /* gap: 5rem; */
}

.single_card_details_img {
    width: 50%;
}

.single_card_details_img img {
    width: 100%;
    max-height: 520px;
    border-radius: 12px;
    object-fit: cover;
}

.single_card_info_details {
    width: 50%;
}

.single_card_info_details h1 {
    font-size: 34px;
    line-height: 47.6px;
    letter-spacing: 2%;
    font-weight: 400;
    max-width: 565px;
}

.single_card_info_details p {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 2%;
    font-weight: 400;
    max-width: 584px;
    color: #807d7e;
}

.single_card_price p {
    padding: 12px 0px;
    font-size: 36px;
    font-weight: 700;
    line-height: 43.2px;
    color: black;
}

.single_card_description p {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 2%;
    color: #807d7e;
    width: 90%;
}

.stock_items_left {
    font-weight: 400;
    color: #807D7E;
    line-height: 25px;
    font-size: 16px;
}

.cart_quantity_icon {
    border-radius: 12px;
    background-color: #F6F6F6;
    width: 100px;
    padding: 8px 10px;
    justify-content: center;

}

@media (max-width: 992px) {
    .single_card_details_section {
        display: flex;
        flex-direction: column !important;
    }

    .single_card_details_img {
        width: 100%;
    }

    .single_card_info_details {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .single_card_container {
        padding: 0;
    }

    .single_card_details_img {
        width: 75%;
    }

    .single_card_details_img img {
        max-height: 300px;
        width: 100%;
    }

    .single_card_details_section {
        padding: 30px;
    }

    .section2_of_singleCard {
        padding: 0 20px;
    }

    .single_card_description p {
        width: 100%;
    }

    .cart_stock_quantity {
        display: flex;
        flex-direction: column !important;
    }
}