.checkout_forms {
  width: 70%;
}

.checkout_input_container {
  width: 48%;
}

.main_title {
  font-weight: 400;
  size: 22px;
  line-height: 33.5px;
  letter-spacing: 2%;
  padding: 20px 0;
}

.checkout_inputs {
  flex-wrap: wrap;
}

.checkout_section {
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px solid #edeef2;
}

.checkout_container {
  padding: 20px;
  border-radius: 12px;
}

.checkout_summary {
  margin: 20px 0;
  padding: 25px;
  border: 2px solid rgb(228, 225, 225);
  max-height: 600px;
  border-radius: 8px;
  width: 30%;
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
  box-sizing: border-box;
}

.main_subtitle {
  color: #3c4242;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 400;
}

.summary_header {
  font-weight: 400;
  font-size: 24px;
  line-height: 33.5px;
  letter-spacing: 2%;
  color: #3C4242;
}

.checkbox_btn[type="checkbox"] {
  display: none;
}

.checkbox_label {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 50%;
  position: relative;
}

.checkbox_label::before {
  content: "\2713";
  font-size: 15px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkbox_btn[type="checkbox"]:checked+.checkbox_label {
  background-color: rgb(0, 0, 0);
}

.summary_subOrder {
  overflow-x: auto;
  /* Enable horizontal scrolling for order_summary section */
  white-space: nowrap;
  /* Prevents items from wrapping */
}

.order_summary img {
  width: 63px;
  height: 63px;
  border-radius: 12px;
  flex-shrink: 0;
}

.order_summary {
  align-items: center;
  border-bottom: 1px solid #edeef2;
  padding: 15px 0;
  flex-shrink: 0;
  white-space: normal;
  width: 100%;
}

.product_summary {
  width: 65%;
  align-items: center;
}

.order_summary p {
  font-size: 16px;
  font-weight: 700;
  /* line-height: 16.8px; */
  color: #3c4242;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.order_summary span,
.summary_subtitle span {
  color: #807d7e;
}

.summary_subtitle {
  color: #000000;
  font-weight: 700;
  font-size: 18px;
  line-break: 21.6px;
  letter-spacing: 2%;
}

.fixed_bottom {
  margin-top: auto;
}

.inputCostumization[type="checkbox"] {
  /* Hide default checkbox */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  /* Adjust size as needed */
  height: 20px;
  /* Adjust size as needed */
  border-radius: 50%;
  /* Makes the checkbox circular */
  border: 2px solid #ccc;
  outline: none;
  cursor: pointer;
}

.inputCostumization[type="checkbox"]:checked {
  background-color: #ccc;
  /* Change background color when checked */
}

@media(max-width:992px) {
  .checkout_forms {
    width: 100%;
  }

  .checkout {
    flex-direction: column-reverse !important;
  }

  .checkout_summary {
    width: 100%;
  }
}

@media(max-width:768px) {
  .checkout_input_container {
    width: 100%;
  }
}