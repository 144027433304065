.dashboard_title {
    font-weight: 400;
    font-size: 28px;
    line-height: 33.5px;
    letter-spacing: 2%;
    color: #3c4242;
}

.dashboard_title_btn {
    border-radius: 8px;
    border: 1px solid #3c4242;
    padding: 10px 35px;
    display: flex;
    gap: 12px;
    font-size: 18px;
    line-height: 21.6px;
    font-weight: 500;
    align-items: center;
    background-color: white;
    color: #3c4242;
}

.dashboard_analytics {
    width: 25%;
    box-sizing: border-box;
    padding: 10px 25px;
}

.dashboard_analytics:not(:first-of-type) {
    border-left: 1px solid #807d7e;
}

.dashboard_analytics h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24.64px;
    letter-spacing: 2%;
}

.dashboard_analytics p {
    font-weight: 700;
    font-size: 48px;
    line-height: 57.6px;
    letter-spacing: 2%;
}

#select_option {
    border-radius: 8px;
    border: 1px solid #3c4242;
    padding: 5px 25px;
    font-size: 18px;
    line-height: 21.6px;
    font-weight: 500;
    background-color: white;
    color: #3c4242;
}

.sortby_label {
    font-weight: 500;
    font-size: 18px;
    line-height: 21.6px;
}

.dashboard_product_searchbar_container {
    position: relative;
}

.dashboard_product_searchbar_input {
    border-radius: 8px;
    border: 1px solid #3c4242;
    padding: 5px 25px;
    font-weight: 500;
    font-size: 18px;
}

.dashboard_product_searchbar_icon {
    position: absolute;
    left: 5px;
    top: 8px;
}

.dashboard_table_style {
    width: 100%;
    border-collapse: collapse;
}

.dashboard_table_columns th {
    background-color: #242424;
    color: white;
}

.dashboard_table_columns th,
.dashboard_table_rows td {
    padding: 20px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.dashboard_table_row_img {
    width: 55px;
    border-radius: 3px;
}

.add_newproduct_currencay_modal {
    position: absolute;
    right: 15px;
    bottom: 10px;
    font-size: 20px;
}

.custom_textarea {
    width: 95%;
    min-height: 175px;
    resize: vertical;
    /* optional: allows vertical resizing */
    border: none;
    padding: 23px 20px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    background-color: #f6f6f6;
}

.product_Modal_btn {
    justify-content: end;
}

.product_Modal_btn_cancel {
    padding: 16px 20px;
    border: none;
    font-size: 18px;
    background-color: white;
    color: #242424;
    cursor: pointer;
}

.product_Modal_btn_delete {
    padding: 16px 20px;
    border: none;
    font-size: 18px;
    background-color: white;
    color: red;
    cursor: pointer;
}

.product_Modal_btn_save {
    border: none;
    padding: 16px 20px;
    border-radius: 8px;
    font-size: 18px;
    background-color: #242424;
    color: white;
    cursor: pointer;
}

.modal-content-wrapper {
    height: 700px;
    /* Set your desired fixed height */
    overflow-x: auto;
    /* Enable horizontal scrolling */
    padding: 20px;
    /* Add padding as needed */
}