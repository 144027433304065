.dashboard_order_options h4 {
    font-weight: 400;
    font-size: 22px;
    /* line-height: 33.5px; */
    letter-spacing: 2%;
    color: #3C4242;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    padding: 0.7rem 2rem 0.2rem 2rem;
}

.dashboard_order_options .selected {
    background-color: #F6F6F6;
    border-bottom: 2px solid #3C4242;
}