:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-tbody>tr>th,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-tbody>tr>td {
  color: #242424;
  background-color: white !important;
}

.flex_r_spacebetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 2%;
  line-height: 26.4px;
}

.cart_container {
  justify-content: space-between !important;
  align-items: flex-start;
  flex-wrap: wrap;
}

.table_header_container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 100px;
  width: 100%;
}

.cart_table_page_size {
  width: 50%;
}

.cartpage_proccess_checkout_subpage {
  background-color: #f3f3f3;
  gap: 2rem;
  width: 25%;
  padding: 40px;
}

.gapping_Cart_page {
  gap: 10px;
}

.cart_table {
  width: 64%;
}

.cart_page_Total {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 2%;
  line-height: 26.4px;
  border-top: 1px solid black;
  padding-top: 20px;
}


.cart_table {
  max-height: 400px;
  /* Adjust the maximum height as needed */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

.cart_table table {
  width: 100%;
  border-collapse: collapse;
}

.cart_table th,
.cart_table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.cart_table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.cart_table tr:hover {
  background-color: #fffdfd;
}

.cart_table .flex_r {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart_table .gap-20 {
  gap: 20px;
}

.cart_table .dashboard_table_row_img {
  width: 50px;
  /* Adjust image width as needed */
  height: 50px;
  /* Adjust image height as needed */
  object-fit: cover;
}

.cart_quantity_icons {
  background-color: #F6F6F6;
  justify-content: center;
  padding: 15px;
  border-radius: 12px;
}

.cart_icon {
  cursor: pointer;
  font-size: 20px;
}

@media (max-width: 996px) {
  .table_header_container {
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
  }


  .cart_table_page_size {
    width: 100%;
    /* overflow-x: auto; */
  }

  .cartpage_proccess_checkout_subpage {
    width: 50%;
  }
}

@media (max-width: 500px) {
  .table_header_container {
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
    width: 85%;
  }

  .cartpage_proccess_checkout_subpage {
    width: 80%;
  }
}