.dashboard_navbar {
    max-width: 1800px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    box-sizing: border-box;
}

.dashboard_navbar_section {
    position: fixed;
    width: 100%;
    z-index: 212;
    background-color: white;
}

.dashboard_navbar_profile {
    padding: 12px;
    background-color: #F6F6F6;
    color: #807D7E;
    border-radius: 8px;
}