.navbar_section {
    border-bottom: 2px solid #bebcbd;
    flex: 1;
    max-height: 100px;
}

.navbar_container {
    max-width: 1500px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    box-sizing: border-box;
}

.navbar_icon {
    width: 25px;
    background-color: #f6f6f6;
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    color: #807D7E;
}

.cart_items_number {
    position: absolute;
    top: -6px;
    right: -6px;
    background-color: #EE1D52;
    color: white;
    min-width: 20px;
    display: flex;
    min-height: 20px;
    font-size: 10px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
}

.navbar_icon.active {
    color: white;
    background-color: #242424;
}

/* Define styles for the LuShoppingCart icon when active */

@media (max-width: 992px) {
    .search-form {
        display: none;
    }

}

.logo_name {
    width: 130px !important;
}