.dashboard_pd_info {
    width: 70%;
}

.dashboard_pd_heading {
    color: #3c4242;
    font-weight: 700;
    line-height: 19.2px;
    font-size: 16px;
    letter-spacing: 2%;
}

.dashboard_pd {
    font-weight: 400;
    padding: 16px 20px;
    background-color: #f6f6f6;
    color: #242424;
    border-radius: 8px;
    line-height: 16.8px;
    align-items: center;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.dashboard_pd_psk {
    display: flex;
    flex-wrap: wrap;
}

.product_details_title {
    font-weight: 600;
    font-size: 50px;
    line-height: 33.5px;
    letter-spacing: 2%;
    color: #3C4242;
}

.dashboard_pd_price {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.dashboard_pd_keywords {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.product_Details_btn_onhover:hover {
    background-color: #EE1D52 !important;
    color: white !important;
}

.dashboard_pd_img img {
    width: 400px;
}

.dashboard_pd_img {
    max-width: 400px;
}

.db_statisctic {
    border-right: 1px solid #807D7E;
}

.db_statisctic h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 2%;
    color: #000000;
}

.db_statisctic p {
    font-weight: 700;
    font-size: 48px;
    line-height: 57px;
    letter-spacing: 2%;
    color: #000000;
}

.dashboard_product_modal_plusimage {
    font-size: 35px;
    background-color: #EAEAEA;
    border-radius: 8px;
    padding: 16px;
}