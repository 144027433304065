.dashboardorder_container {
    width: 100%;
    justify-content: space-between;
}

.dashboard_order_serie {
    justify-content: space-between;
    align-items: center;
    background-color: #f6f6f6;
    padding: 35px 80px;
    border-radius: 12px;
}

.dashbord_heading h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #3c4242;
}

.dashbord_heading p {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #807d7e;
}

.dashboard_order_total_price p {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #807d7e;
}

.db_costumize_order_price {
    color: #3C4242;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
}

.dashbord_order_info {
    flex-wrap: wrap;

}

.dashboard_order_customer_details {
    width: 50%;
    /* justify-content: center; */
    padding: 47px 0;
}

.dashboard_order_info_icons {
    border-radius: 8px;
    padding: 15px 20px;
    background-color: #EAEAEA;
    font-size: 25px;
    color: #807D7E;
}

.title_details_style {
    color: #3C4242;
    font-weight: 600;
    font-size: 25px;
    line-height: 25px;
}


.details_covers h1 {
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    letter-spacing: 2%;
    color: #3C4242;
}

.details_covers p {
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;
    letter-spacing: 2%;
    color: #807D7E;
}

.dashboard_order_amount {
    justify-content: end;
    margin-top: 20px;
}

.dashboard_order_name {
    color: #3C4242;
    line-height: 26px;
    font-weight: 500;
    letter-spacing: 2%;
    font-size: 22px;
}