* {
    margin: 0;
    padding: 0;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.outlet_footer {
    flex: 1;
}

.flex_r {
    display: flex;
    flex-direction: row;
}

.flex_c {
    display: flex;
    flex-direction: column;
}

.space_between {
    justify-content: space-between;
}

.flex_end {
    justify-content: end;
}

.bold {
    font-weight: 700;
}

.padding-15 {
    padding: 15px;
}

.padding-0-5 {
    padding: 0 5rem;
}

.align_items_center {
    align-items: center;
}

.flex_wrap {
    flex-wrap: wrap;
}

hr {
    border: 1px solid #BEBCBD50;
    border-bottom: 0;
}

.gap-40 {
    gap: 40px;
}

.gap-30 {
    gap: 30px;
}

.gap-20 {
    gap: 20px;
}

.gap-10 {
    gap: 10px;
}

.border-bottom {
    border-bottom: 1px solid #BEBCBD;
}

.width-400 {
    width: 400px;
}

.light_background {
    background-color: #f6f6f6;
}

.main_container {
    display: flex;
    justify-content: center;
    margin: 30px auto;
    max-width: 1500px;
    width: 90%;
    flex: 1;
}

.main_header {
    font-weight: 400;
    font-size: 28px;
    line-height: 33.5px;
    letter-spacing: 2%;
    border-left: 6px solid black;
    padding: 0px 25px 20px 25px;
}

.auth_page {
    max-width: 610px;
}

.dashboard_page {
    margin-left: 230px;
    padding: 105px 30px;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.label_inputs {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.checkout_input {
    padding: 16px 20px;
    border-radius: 8px;
    background-color: #f6f6f6;
    max-width: 100%;
    border: none;
}

.product_modal_group .product_modal_group_item {
    width: calc(100%/3);
    position: relative;
}

.label {
    margin-top: 20px;
    color: #3c4242;
    font-weight: 600;
    line-height: 19.2px;
    font-size: 16px;
    letter-spacing: 2%;
}

.image_input {
    max-width: 300px;
    padding: 12px 15px;
    gap: 10px;
    background-color: #F6F6F6;
    border-radius: 8px;
}

.image_placeholder {
    width: 60px;
    height: 60px;
    background-color: #EAEAEA;
    padding: 12px;
    font-size: 28px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: #3C4242;
}

.image_input img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
}

.shadow {
    box-shadow: 2px 2px 0px 4px #00000005;
}

.outlined-btn {
    border-radius: 8px !important;
    border: 1px solid #3c4242 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #3c4242 !important;
}

.outlined-btn:hover {
    background-color: #3c424210 !important;
}

.outlined_input {
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 8px;
    line-height: 21px;
    border: 1px solid #3C4242;
    cursor: pointer;
    user-select: none;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th {
    background-color: #242424;
    color: white;
}

.table th,
.table td {
    padding: 20px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.page_pagination {
    cursor: pointer;
    padding: 0 5px;
    font-size: 20px;
}

.current-page {
    text-decoration: underline;
}

.table_footer_arrow {
    padding: 0.5rem 2rem;
    font-size: 20px;
    border: 1px solid #807D7E;
    color: #807D7E;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.icon_style {
    color: #807D7E;
    font-size: 20px;
}

.table_pages {
    color: #807D7E;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.2px;
    letter-spacing: 2%;
}

.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 20px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #c4bfbf;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media (max-width: 768px) {
    .auth_page {
        width: 90%;
    }

    .product_modal_group {
        flex-wrap: wrap;
    }

    .product_modal_group .product_modal_group_item {
        width: 100%;
    }
}