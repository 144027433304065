.confirmed_order {
    border: 2px solid black;
    border-radius: 12px;
    width: 300px;
    max-height: 460px;
    padding: 45px 20px;
}

.confirmed_order p {
    font-weight: 400;
    font-size: 30px;
    line-height: 33.36px;
    text-align: center;
}