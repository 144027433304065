.button_search_section {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.searchbar_button {
    padding: 19px;
    border: 0;
    background: black;
    color: white;
    border-radius: 0 10px 10px 0;
    font-size: 18px;
    position: absolute;
    right: -10px;
    bottom: 0px;
}