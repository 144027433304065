.profile_details {
    padding-top: 2rem;

}

.profile_details h1 {
    font-weight: 400;
    font-size: 22px;
    line-height: 33.5px;
    letter-spacing: 2%;
    color: #3C4242;
}

.profile-your-headtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 21.5px;
    letter-spacing: 2%;
    color: #807D7E;
}

.profile_your_subtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 21.5px;
    letter-spacing: 2%;
    color: #3C4242;
}

.profile_name_change p {
    font-weight: 700;
    font-size: 18px;
    line-height: 21.5px;
    letter-spacing: 2%;
    color: #3C4242;
}

.profile_name {
    align-items: flex-end;
    padding-bottom: 20px;
    border-bottom: 1px solid #EDEEF2;
}

.business_profile_btn {
    padding: 16px 20px !important;
    width: 70px !important;
}

.profile_modal_btn {
    padding: 16px 20px !important;
    background-color: transparent !important;
    color: #3C4242 !important;
    width: 80px !important;
}

.profile_company_details {
    flex-wrap: wrap;
}

.profile_details_section {
    width: 75%;
}

.profile_company_section {
    width: 20%;
}

.profile_company_logo {
    max-width: 80px !important;
    /* padding-left: 1rem; */
}

.contact_details_wrapping {
    flex-wrap: wrap;
}

.contat_details_size {
    width: 35%;
}

.profile_contact_icons {
    font-size: 1.8rem;
    color: #807D7E;
}