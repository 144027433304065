.style_404{
    align-items: center;
    border-radius: 12px;
    width: 50%;
    max-height: 460px;
    gap: 20px;
    padding: 45px 20px;
}
.style_404 h1 {
    font-weight: 400;
    font-size: 34px;
    line-height: 41.89px;
}
.style_404 p{
    font-weight: 500;
    font-size: 16px;
    line-height: 19.95px;
    text-align: center;
    letter-spacing: 2%;
    color: #807D7E;
}
@media(max-width:996px){
    .style_404 h1 {
     text-align: center;
    } 
}
@media(max-width:500px){
    .style_404 img {
        width: 250px;
    }
    .style_404{
        width: 100%;
    }
}