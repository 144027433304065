.card_container {
    width: 25%;
    padding: 25px;
    box-sizing: border-box;
    position: relative;
}

.card_image img {
    aspect-ratio: 1 / 1;
    width: 100%;
    border-radius: 12px;
    object-fit: cover;
    cursor: pointer;
}

.card_info {
    color: #3c4242;
}

.card_info h5 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.card_info {
    align-items: center;
}

.card_info p {
    background-color: #f6f6f6;
    padding: 10px;
    border-radius: 8px;
}

.card_icon {
    width: 16px;
    height: 17px;
    position: absolute;
    top: 50px;
    right: 35px;
    background-color: white;
    padding: 10px;
    border-radius: 20px;
    box-sizing: content-box;
}

.card_icon_outofstock {
    position: absolute;
    top: 45px;
    left: 21px;
    background-color: #ee1d5270;
    color: white;
    padding: 4px 6px;
    border-radius: 5px 5px 0px 0px;
    box-sizing: content-box;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    transform: rotate(-45deg);
}

.card_icon:hover {
    background-color: #f6f6f6;
    transition: all 300ms ease;
}

@media (max-width: 992px) {
    .card_container {
        width: calc(100% / 3);
        padding: 20px;
    }
}

@media (max-width: 786px) {
    .card_container {
        width: 50%;
        padding: 10px;
    }

    .card_icon_outofstock {
        padding: 2px 7px;
        top: 35px;
        left: 4px;
    }
}

@media (max-width: 500px) {
    .card_icon {
        top: 20px;
        right: 20px;
        padding: 5px;
    }

    .card_info p {
        font-size: 12px;
    }

    .card_icon_outofstock {
        padding: 1px 3px;
        top: 29px;
        left: 4px;
    }
}