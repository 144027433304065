.footer_container {
    background-color: #242424;
    margin-top: 20px;
    width: 100%;
    flex: 1;
    max-height: 350px;
}

.footer_sub_container {
    max-width: 1500px;
    margin: 0 auto;
    padding: 50px 0;
    border-bottom: 1px solid #7f7a7a;
    flex-wrap: wrap;
    width: 90%;
    gap: 20px;
    /* justify-content: space-evenly; */
}

.footer_sub_container p {
    color: white;
    line-height: 30px;
    size: 18px;
}

.footer_about {
    width: 50%;
}

.footer_about>div {
    align-items: center;
}

.footer_info {
    width: 20%;
}

.footer_title h1 {
    color: white;
}

.footer_copy_right {
    justify-content: center;
    margin: 15px 0;
}

.footer_copy_right p {
    font-weight: bold;
    color: white;
}

.social_icon {
    padding: 10px;
    background-color: #F6F6F6;
    border-radius: 12px;
    color: #2A2F2F;
    cursor: pointer;
}

.footer_list li {
    list-style-type: none;
    color: white;
}

.background_footer_logo {
    background-color: white;
    border-radius: 10px;
    width: 150px;
}

@media (max-width: 992px) {

    .footer_sub_container {
        gap: 50px;
    }

    .footer_about {
        width: 100%;
    }

    .footer_info {
        width: 40%;
    }
}

@media (max-width: 576px) {
    .footer_container {
        max-height: 100%;
    }

    .footer_about {
        width: 100%;
        align-items: center;
    }

    .footer_about p {
        text-align: center;
    }

    .footer_info {
        width: 100%;
        align-items: center;
    }

    .footer_contact {
        width: 100%;
        align-items: center;
    }

    .footer_list {
        align-items: center;
    }

    .footer_copy_right p {
        font-weight: bold;
        font-size: 14px;
    }
}