.home_page {
    justify-content: center;
}

.display_none_Home {
    width: 100%;
}

@media (min-width: 992px) {
    .display_none_Home {
        display: none;
    }

}